import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Page from "../templates"
import SEO from "../templates/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const AnneEwers = ({ data }) => {
  return (
    <Page>
      <SEO title="Anne Ewers" />
      <h1 className="text-primary">Über mich</h1>
      <p>Künstlerin Anne Ewers</p>
      <Row>
        <Col xs={12} md={6} className="mt-4 mb-5">
          <GatsbyImage
            style={{ maxWidth: "500px" }}
            loading="auto"
            image={getImage(data.profile.image2)}
            alt="Anne Ewers"
          />
        </Col>
        <Col xs={12} md={{ order: "last" }} className="mt-4 mb-5">
          <div className="m-auto" style={{ maxWidth: "50rem" }}>
            <hr className="d-none d-md-block mb-5" />
            <h2 className="mb-4 text-primary">
              „Es geht immer um die Spur der menschlichen Berührung, die ein
              Gemälde in sich trägt... „
            </h2>
            <p style={{ whiteSpace: "pre-line" }}>{data.profile.text.text}</p>
          </div>
        </Col>
        <Col xs={12} md={6} className="mt-4 mb-5">
          <h5 className="d-md-none mb-3">Meine Stationen</h5>
          <ul>
            <li>
              <p>1956 geboren in Paderborn</p>
            </li>
            <li>
              <p>Kunststudium an der Universität Paderborn</p>
            </li>
            <li>
              <p>
                Studium an der Freien Pädagogischen Hochschule für
                Waldorfpädagogik in Stuttgart
              </p>
            </li>
            <li>
              <p>Lehrerin an der Tübinger Waldorfschule</p>
            </li>
            <li>
              <p>
                Gaststudentin an der freien Kunststudienstätte in Ottersberg
              </p>
            </li>
            <li>
              <p>
                1987 Gründung der Freien Kunstschule für Kinder, Jugendliche und
                Erwachsene
              </p>
            </li>
            <li>
              <p>Berufsbegleitendes Seminar für Heilpädagogik</p>
            </li>
            <li>
              <p>
                regelmäßige intensive Kunststudien in Malerei und Bildhauerei
                auf dem Hof "Wilstedter Mühle"
              </p>
            </li>
            <li>
              <p>seit 1987 Kunstpädagogin und freischaffende Künstlerin</p>
            </li>
          </ul>
        </Col>
      </Row>
    </Page>
  )
}

export default AnneEwers

export const query = graphql`
  query {
    profile: contentfulArtist(contentful_id: { eq: "7wpPQwHouWUbwVVrS8nfmW" }) {
      fistName
      lastName
      text {
        text
      }
      image2 {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`
